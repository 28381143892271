import { Flex, HStack, Text } from '@chakra-ui/react'
import FacebookIcon from '@components/icons/facebook-icon'
import InstagramIcon from '@components/icons/instagram-icon'
import LinkedInIcon from '@components/icons/linkedin-icon'
import TwitterIcon from '@components/icons/twitter-icon'

const ErrorFooter = ({
  storeName,
  facebook,
  twitter,
  instagram,
  linkedin,
}: {
  storeName?: string
  facebook?: string
  twitter?: string
  instagram?: string
  linkedin?: string
}) => {
  return (
    <>
      <Flex
        px={{ base: '5', lg: '16', xl: '20' }}
        py="4"
        align="center"
        justifyContent="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <Text fontWeight="500" textAlign="center">
          © Copyrights <Text as="span">{storeName || 'QShop'}</Text> | All
          Rights Reserved 
        </Text>
        <HStack spacing="4" mt={{ base: '3', md: '0' }}>
          <a href={facebook || "https://web.facebook.com/qshop.ng"} target="blank">
            <Text as="span" _hover={{ color: '#FF9900' }}>
              <FacebookIcon />
            </Text>
          </a>
          <a href={twitter || "https://twitter.com/qshopnigeria"}>
            <Text as="span" _hover={{ color: '#FF9900' }}>
              <TwitterIcon />
            </Text>
          </a>
          <a href={instagram || "https://instagram.com/qshop.ng"}>
            <Text as="span" _hover={{ color: '#FF9900' }}>
              <InstagramIcon />
            </Text>
          </a>
          <a href={linkedin || "https://www.linkedin.com/company/qshop-tech/"}>
            <Text _hover={{ color: '#FF9900' }}>
              <LinkedInIcon />
            </Text>
          </a>
        </HStack>
      </Flex>
    </>
  )
}

export default ErrorFooter
