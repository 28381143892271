import { Box, Flex, Text, chakra } from '@chakra-ui/react'
import Image from 'next/image'
import React from 'react'
import ErrorFooter from './ErrorFooter'
import QshopLogo from '@assets/images/qshop_long_logo.svg'
import PageNotFoundImage from '@assets/images/page-not-found.svg'


const NotFound = () => {
  return (
    <Flex height="100vh" maxH="100vh" minH="100vh" direction="column" pb="6">
      <chakra.a
        pl={{ base: '5', lg: '20' }}
        py="4"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        href="https://qshop.tech/"
      >
        <Image src={QshopLogo} alt="Qshop Logo" width={120} height={40} />
      </chakra.a>
      <Flex
        justify="center"
        align="center"
        direction="column"
        flexGrow="1"
        position="relative"
        mt={{ base: '3', md: '5', lg: '8' }}
      >
        <Box position="relative" flexShrink="1" h="50%" w="full" px="4">
          <Image
            src={PageNotFoundImage}
            alt="Page not found"
            style={{ paddingRight: '12px', paddingLeft: '12px' }}
            fill
            priority
          />
        </Box>
        <Text
          align="center"
          fontWeight="700"
          fontSize={{ base: '20px', lg: '28px' }}
          mt="4"
          width={{ base: '95%', md: '80%', lg: '70%' }}
        >
          Start Selling with your own eCommerce website in minutes!{' '}
          <a href="https://qshop.tech/">
            <Text as="span" color="#FF9900" textDecoration="underline">
              Click here
            </Text>
          </a>{' '}
          to find out more.
        </Text>
      </Flex>

      <ErrorFooter />
    </Flex>
  )
}

export default NotFound
